(function (wnd) {
    function CookieBar(params) {
        try {
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    CookieBar.prototype = {
        container: undefined,
        button: undefined,
        options: {
            position: 'top',
            confirm_url: 'ajaxcontroller/cookieConfirm',
        },
        texts: {
            button_text: '',
            cookie_confirm_bar_txt: ''
        },
        init: function () {
            this.createContent();
            this.addDOMEvents();
            if (this.getText('cookie_confirm_bar_txt') && this.getText('cookie_confirm_bar_txt') !== 'cookie_confirm_bar_txt' ) {
                this.container.addClass('opened');
            }
        },
        addDOMEvents: function () {
            var _self = this;

            if (this.button) {
                this.button.click(function () {
                    _self.closeCookieBar();
                });
            }
        },
        createContent: function () {
            this.container = $('<div>', {
                'class': 'cookie-bar position-' + this.getPosition(),
            });

            var inner = $('<div>', {
                class: 'inner-content'
            });

            var innerContent = $('<div>', {
                class: 'content-width cookie-content',
                html: '<div class="left-content">' + this.getText('cookie_confirm_bar_txt') + '</div>'
            });

            this.button = $('<a>', {
                href: 'javascript:void(0)',
                class: 'button',
                html: this.getText('button_text')
            });

            var rightContent = $('<div>', {
                class: 'right-content',
                html: this.button
            });

            innerContent.append(rightContent);
            inner.append(innerContent);
            this.container.append(inner);


            $('body').append(this.container);
        },
        openCookieBar: function ()  {
            this.container.addClass('opened');
        },
        closeCookieBar: function () {
            this.container.removeClass('opened');

            if (this.getConfirmUrl()) {
                $.ajax({
                    url:this.getConfirmUrl(),
                    dataType:'json',
                    type:'post'
                });
            }
        },
        setParams: function (params) {
            if (params.texts) this.setTexts(params.texts);
            if (params.options) this.setOptions(params.options);
            if (params.confirm_url) this.confirm_url
        },
        setTexts: function (texts) {
            this.texts = $.extend({}, this.texts, texts);
        },
        setOptions: function (options) {
            this.options = $.extend({}, this.options, options);
        },
        getPosition: function () {
            return this.getOption('position');
        },
        getConfirmUrl: function() {
            return this.getOption('confirm_url');
        },
        getOption: function (key) {
            return typeof this.options[key] !== 'undefined' ? this.options[key] : false;
        },
        getText: function (key) {
            return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
        },
    };

    wnd.CookieBar = CookieBar;
})(window);
