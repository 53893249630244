(function($) {

    var LoginPopup = function(element, options) {

        this.element = element;
        this.defaults = {animationDuration: 300, feedbackTimeout: 5000};

        this.options = $.extend({}, this.defaults, options);

        this.form = element.find('form');

        if (!this.form.length) return;

        this.username = this.form.find('input[type="text"]');
        this.password = this.form.find('input[type="password"]');
        this.dismiss_button = element.find('[data-purpose="dismiss-button"]');
        this.submit_button = element.find('[data-purpose="submit-button"]');
        this.feedback = element.find('[data-purpose="feedback"]');

        this.init();

    };

    LoginPopup.prototype = {

        init: function() {
            var _self = this;
            if (login_link) {
                $(document).on('click', 'a[href="' + login_link + '"]', function(e) {
                    e.preventDefault();
                    _self.open();
                    $(document).trigger('shoppingstreet.loginclicked');
                    return false;
                });
            }

            _self.submit_button.click(function(e) {
                $.post(login_link, _self.form.serialize(), function(ret) {
                    if (ret.success) {
                        _self.close();
                        setTimeout(function() {
                            //Ez a sor megakadályozza a form újraküldését, ami checkoutnál jól jön
                            history.replaceState(null, document.title, location.href);
                            window.location.reload();
                        }, _self.options.animationDuration);
                    }
                    else {
                        _self.showMessage(ret.msg);
                    }
                }, 'json');
                return false;
            });

            if (_self.dismiss_button.length) {
                _self.dismiss_button.click(function() {
                    _self.close();
                    return false;
                });
            }
        },

        open: function() {
            var _self = this;

            var other = $('.system-overlay.opened');
            if (other.length) {
                other.removeClass('opened');
                setTimeout(function() {
                    _self.element.addClass('opened');
                }, _self.options.animationDuration);
            }
            else {
                _self.element.addClass('opened');
            }
        },

        close: function() {
            var _self = this;
            _self.element.removeClass('opened');
        },

        showMessage: function(message) {
            var _self = this;

            if (_self.feedback.length) {
                _self.feedback.html(message).show().addClass('has-feedback');
                setTimeout(function() {
                    _self.feedback.removeClass('has-feedback');
                    setTimeout(function() {
                        _self.feedback.hide();
                    }, _self.options.animationDuration);
                }, _self.options.feedbackTimeout)
            }
            else {
                if(typeof systemMessenger != 'undefined'){
                    systemMessenger.showErrorMessage(message);
                }else{
                    $.systemMessage(message, 'error');
                }
            }
        }

    };

    $.fn.loginPopup = function(options) {
        return this.each(function() {
            new LoginPopup($(this), options);
        });
    };
})(jQuery);
