(function(wnd){
    function ContactForm(container, params) {
        try {
            if (!container || (container && container.length == 0)) {
                throw 'Container is required!';
            }

            this.container = container;
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (error) {
            console.error(error);
        }
    }

    ContactForm.prototype = {
        container: undefined,
        form: undefined,
        button:undefined,
        sendedForm: false,
        init: function() {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            this.form = this.container.find('form');
            this.button = this.form.find('[data-purpose="send-button"]');
        },
        addDOMEvents: function() {
            var _self = this;

            if (this.form && this.form.length === 1) {
                this.form.submit(function(e){
                    e.preventDefault();

                    _self.sendForm();
                });

                if (this.button && this.button.length > 0) {
                    this.button.click(function(){
                        _self.sendForm();
                    });
                }
            }

            $(document).on('shoppingstreet.product_variation_changed', function(e, variation) {
                _self.form.find('[name="variationId"]').val(variation.id);
            });
        },
        setParams: function(params) {

        },
        sendForm: function() {
            if (!this.sendedForm) {
                var _self = this;

                this.sendedForm = true;

                $.ajax({
                    url:this.form.attr('action'),
                    type:'post',
                    dataType:'json',
                    data: this.form.serialize(),
                    beforeSend: function() {
                        _self.form.find('.has-error').removeClass('has-error');
                    },
                    success: function(resp) { 
                        if (resp.success) {
                            _self.resetForm();

                            if (typeof resp.message != 'undefined') {
                                systemMessenger.showMessage(resp.message, 'info');
                            }
                        } else {
                            if (typeof resp.errors != 'undefined' && Object.keys(resp.errors).length > 0) {
                                var showedMessage = [];
                                $.each(Object.keys(resp.errors), function (val, key) {
                                    var item = _self.form.find('[name="' + key + '"]');
                                    var msg = resp.errors[key];
                                    if (msg.trim() !== '' && showedMessage.indexOf(msg) < 0) {
                                        showedMessage.push(msg);
                                        systemMessenger.showErrorMessage(resp.errors[key]);
                                    }
                                    
                                    if (item.length > 0) {
                                        if (typeof inputValidator != 'undefined') {
                                            inputValidator.removeFeedbackClasses(item[0]);
                                            inputValidator.setFeedback(item[0], false);
                                        } else {
                                            if (item.data('feedback-target') == 'label') {
                                                _self.form.find('label[for="' + (item.attr('id')) + '"]').addClass('has-error');
                                            } else if (item.data('feedback-target') == 'parent') {
                                                item.parent().addClass('has-error');
                                            } else {
                                                item.addClass('has-error');
                                            }
                                        }
                                    }
                                });
                            }
                            // Ez az esemény a gyerekruhában került be, célja, hogy egyedi class-t teszünk clickre, amit ilyenkor leveszünk.
                            $(document).trigger("shoppingstreet.removebuttonresponse", _self.button);
                        }
                    },
                    complete: function() {
                        setTimeout(function () {
                            _self.sendedForm = false;
                        }, 500);
                    }
                });
            }
        },
        resetForm: function() {
            this.form.find('.field').val('');
            this.form.find('[type="checkbox"]').prop('checked', false);
            this.form.find('select').val('');
        }
    }

    wnd.ContactForm = ContactForm;
})(window);
