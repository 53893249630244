(function (window) {
    function ShoppingStreetUserForm(form, options) {
        try {
            if (!form)
                throw 'Please give me a <form>';

            this.form = form;

            if (options)
                this.setParams(options);

            this.init();

        } catch (e) {
            console.log(e);
        }
    };

    ShoppingStreetUserForm.prototype = {
        form: undefined,
        button: undefined,
        options: {
            ajax: false,
            url: undefined,
            popupMode: false,
            submit_on_enter: true,
            error_class: 'has-error'
        },
        type: 'registration', // login - registration
        sendedForm: false,
        handledRecaptcha: false,
        additionalContents: undefined,
        init: function () {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function () {
            this.button = this.form.find('[data-purpose="send-button"]');
            this.additionalContents = this.form.find('[data-purpose="additional-content"]');
        },
        addDOMEvents: function () {
            var _self = this;

            this.button.click(function () {
                $(document).trigger('shoppingstreet.addbuttonresponse', $(this));
                _self.sendForm();
            });

            if (this.options.submit_on_enter) {
                this.form.find('input').on('keypress', function (e) {
                    if (e.keyCode == 13) {
                        e.preventDefault();
                        $(document).trigger('shoppingstreet.addbuttonresponse', _self.button);
                        _self.sendForm();
                    }
                });
            }

            if (this.additionalContents.length > 0) {
                this.form.find('[data-purpose="additional-content-chk"]').change(function(){
                    var type = $(this).data('type');
                    var checked = $(this).prop('checked');
                    var hideTarget = $(this).data('hide-target');
                    if (type) {
                        var target = _self.form.find('[data-purpose="additional-content"][data-type="'+type+'"]');
                        if (target.length > 0) {
                            target.toggleClass('active').slideToggle();
                        }
                    }

                    if (hideTarget) {
                        var item = $(hideTarget);
                        if (item.length > 0) {
                            if (checked && item.is(':visible')) {
                                item.hide();
                            } else if (!checked && !item.is(':visible')) {
                                item.show();
                            }
                        }
                    }
                    
                });
            }
        },
        setParams: function (params) {
            if (params.options) this.setOptions(params.options);
            if (params.type) this.setType(params.type);
        },
        setOptions: function (params) {
            this.options = $.extend({}, this.options, params);
        },
        setType: function (type) {
            this.type = type;
        },
        getOptions: function (key) {
            return typeof this.options[key] != 'undefined' ? this.options[key] : false;
        },
        isAjax: function () {
            return this.getOptions('ajax');
        },
        handleRecaptcha: function() {            
            var hasRecaptcha = webshopUtils.hasRecaptcha();
            if (hasRecaptcha && this.type == 'registration') {
                var _self = this;
                this.form.find('[name="g-recaptcha-response"]').remove();
                this.form.find('[name="g-recaptcha-action"]').remove();
                
                grecaptcha.ready(function() {
                    grecaptcha.execute(webshopUtils.getRecaptchaSiteKey(), {action: _self.type}).then(function(token) {
                        _self.form.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
                        _self.form.prepend('<input type="hidden" name="g-recaptcha-action" value="' + _self.type + '">');
                        _self.sendedForm = false;
                        _self.handledRecaptcha = true;    
                        _self.sendForm();                            
                    });
                });
                
            } else {
                this.sendedForm = false;
                this.handledRecaptcha = true;

                this.sendForm();
            }
        },
        sendForm: function () {
            if (this.isAjax()) {
                var _self = this;
                if (!this.sendedForm) {
                    _self.sendedForm = true;
                    
                    if (webshopUtils.hasRecaptcha() && !_self.handledRecaptcha && this.type == 'registration') {
                        this.handleRecaptcha();
                        return;
                    }
                    
                    $.ajax({
                        url: _self.form.attr('action'),
                        dataType: 'json',
                        type: 'post',
                        data: _self.form.serialize(),
                        beforeSend: function() {
                            _self.form.find('.'+_self.options.error_class).removeClass(_self.options.error_class);
                        },
                        success: function (resp) {
                            if (webshopUtils.hasRecaptcha() && _self.type == 'registration') {
                                _self.handledRecaptcha = false;
                            }

                            if (resp.success) {
                                var dataPurpose = _self.form.data('purpose');
                                if (typeof dataPurpose != 'undefined') {

                                    if (dataPurpose.trim() == 'registration-form') {
                                        if (typeof googleTracker != 'undefined') {
                                            googleTracker.trackEvent('sign_up', 'Lead', 'Registration');
                                        } else if (typeof ga != 'undefined') {
                                            ga('send', 'event', 'Lead', 'Registration');
                                        }

                                        if (typeof fbq != 'undefined') {
                                            fbq('track', 'CompleteRegistration');
                                        }
                                    } else if (dataPurpose.trim() == 'login-form') {
                                        if (typeof googleTracker != 'undefined') {
                                            googleTracker.trackEvent('login', 'User Actions', 'Login');
                                        } else if (typeof ga != 'undefined') {
                                            ga('send', 'event', 'User Actions', 'Login');
                                        }
                                    }
                                }

                                var timeout = typeof resp.timeout != 'undefined' ? resp.timeout : 0;
                                setTimeout(function () {
                                    if (typeof resp.url != 'undefined' && resp.url != '') {
                                        document.location.href = resp.url;
                                    } else {
                                        if (typeof resp.reload === 'undefined' || (typeof resp.reload != 'undefined' && resp.reload))
                                            document.location.reload();
                                    }

                                }, timeout);

                                var remodalParent = _self.form.parents('.remodal');
                                if (remodalParent.length > 0) {
                                    $('[data-remodal-id="' + remodalParent.data('remodal-id') + '"]').remodal().close();
                                }
                                if (typeof resp.message != 'undefined') {
                                    systemMessenger.showMessage(resp.message, 'info');
                                }
                            } else {
                                if (typeof resp.errors != 'undefined' && Object.keys(resp.errors).length > 0) {
                                    var showedMessage = [];
                                    $.each(Object.keys(resp.errors), function (val, key) {
                                        var item = _self.form.find('[name="' + key + '"]');
                                        var msg = resp.errors[key];
                                        if (msg.trim() !== '' && showedMessage.indexOf(msg) < 0) {
                                            showedMessage.push(msg);
                                            systemMessenger.showErrorMessage(resp.errors[key]);
                                        }
                                        
                                        if (item.length > 0) {
                                            if (typeof inputValidator != 'undefined') {
                                                inputValidator.removeFeedbackClasses(item[0]);
                                                inputValidator.setFeedback(item[0], false);
                                            } else {
                                                if (item.data('feedback-target') == 'label') {
                                                    _self.form.find('label[for="' + (item.attr('id')) + '"]').addClass(_self.options.error_class);
                                                } else if (item.data('feedback-target') == 'parent') {
                                                    item.parent().addClass(_self.options.error_class);
                                                } else {
                                                    item.addClass(_self.options.error_class);
                                                }
                                            }
                                        }
                                    });
                                }
                                // Ez az esemény a gyerekruhában került be, célja, hogy egyedi class-t teszünk clickre, amit ilyenkor leveszünk.
                                $(document).trigger("shoppingstreet.removebuttonresponse", _self.button);

                                setTimeout(function () {
                                    _self.sendedForm = false;
                                }, 500);
                            }
                        }
                    });
                }
            } else {
                this.form.submit();
            }
        }
    };

    window.shoppingStreetForm = ShoppingStreetUserForm;

})(window);
