var windowWidth = undefined;

$(document).ready(function() {
  windowWidth = window.innerWidth;

  var resizeFn = function() {
    if (windowWidth != window.innerWidth) {
      windowWidth = window.innerWidth;
      $(document).trigger('shoppingstreet.window_width_changed', windowWidth);
    }
  }

  window.addEventListener('resize', resizeFn);

  $('.readonly').attr('readonly', true);
  
  $('[data-purpose="remodal-open"]').click(function() {
    if ($(this).data('popup-target')) {
      openRemodalContent($(this).data('popup-target'));
    }
  });

  $('[data-purpose="remodal-close"]').click(function() {
    $(this).parents('.remodal').remodal().close();
  });

  $(document).click(function(e) {
    if (!$(e.target).hasClass('opened-child')) {
      $('.has-submenu.opened').removeClass('opened');
    }
  });

  $('[data-purpose="logout-btn"]').click(function() {
    logoutUser();
  });

  $('[data-purpose="quantity-change-button"]').click(function(e) {
    if ($(this).hasClass('inactive')) {
      e.preventDefault();
      return false;
    }

    var type = $(this).data('type');
    var parent = $(this).parents('[data-purpose="quantity-control-panel"]');
    if (parent) {
      var field = parent.find('[data-purpose="quantity"]');
      if (field) {
        var value = parseInt(field.val()) + parseInt($(this).data('value'));
        field.val(value);
        field.change();

        var minus = parent.find('[data-purpose="quantity-change-button"][data-type="minus"]');
        if (minus) {
          if (value == 1) {
            minus.addClass('inactive');
          } else if (value > 1 && minus.hasClass('inactive')) {
            minus.removeClass('inactive');
          }
        }
      }
    }
  });

  $('[data-purpose="facebook-login"]').click(function(){
      if(typeof FB != 'undefined'){
          var btn = $(this);
          btn.addClass("show-animation");
          // btn.attr('disabled', true);
          if(fb_status === 'connected'){
              loginWithFacebook(btn);
          }else{
              FB.login(function(response){
                  if(response.status === 'connected'){
                      loginWithFacebook(btn);
                  }
              });
          }

      }else{
        console.log('Nincs FB js');
      }
  });

  $('[data-purpose="currency-change"]').click(function(){
    var currency_id = $(this).data('currency-id');
    if (currency_id && !$(this).hasClass("disabled") && !$(this).hasClass('active')) {
      if (typeof cart_controller != 'undefined' && cart_controller) {
        $(this).addClass('disabled');
        var btn = $(this);
        $.ajax({
          url:cart_controller+'/changeCurrency',
          dataType:'json',
          type:'post',
          data:{currency_id: currency_id},
          success: function(response) {
            window.location.reload();
          },
          complete: function(){
            btn.removeClass('disabled');
          }
        });
      }
    }
  });

  $('[data-purpose="send-privacy-form-btn"]').click(function(){
    var btn = $(this);
    btn.attr('disabled', true);
    $(document).trigger('shoppingstreet.addbuttonresponse', btn);
    var form = btn.parents('form');
    $.ajax({
      url: form.attr('action'),
      dataType:'json',
      type: 'post',
      data: form.serialize(),
      beforeSend: function() {
        form.find('.has-error').removeClass('has-error');
      },
      success: function(resp) {
        if (resp.success) {
          if (typeof resp.url !== 'undefined' && resp.url !== '') {
            document.location.href = resp.url;
          } else {
            document.location.reload();
          }
        } else {
          if (resp.errors) {
            var showedMessage = [];
            $.each(resp.errors, function (index, value) {
                var item = form.find('input[name="' + index + '"]');
                if (typeof value == 'string' && showedMessage.indexOf(value) < 0 && typeof systemMessenger !== 'undefined') {
                    showedMessage.push(value);
                    systemMessenger.showErrorMessage(value);
                }

                if (item.length > 0) {
                  if (typeof inputValidator !== 'undefined') {
                      inputValidator.removeFeedbackClasses(item[0]);
                      inputValidator.setFeedback(item[0], false);
                  } else {
                      item.addClass("has-error");
                  }
                }
            });
          }
        }
      },
      complete: function() {
        setTimeout(function(){
          btn.removeAttr('disabled');
          $(document).trigger('shoppingstreet.removebuttonresponse', btn);
        }, 500);
      }
    });
    
  });
  
  var missingMailPopup = $('[data-remodal-id="missing-email-remodal"]');
  if (missingMailPopup && missingMailPopup.length > 0 && typeof localStorage !== 'undefined') {
    var seenTime = localStorage.getItem("missingEmailPopup");
    var now = new Date();
    var expiry = new Date();
    expiry.setDate(now.getDate() + 90);

    if (seenTime === null || (seenTime !== null && new Date(seenTime) < now)) {
      setTimeout(function(){
        localStorage.setItem("missingEmailPopup", expiry);
        missingMailPopup.remodal().open();
      }, 10000);
    }
  }

  window.addEventListener('message', function(e){
    selectTofShopPoint(e);
  });

  $('.accordion-list').each(function(ind, el) {

		var options = {};
		var th = $(el);

		if (th.data('single-active-item') != undefined) options.singleActiveItem = true;
		if (th.data('active-index') != undefined) options.activeIndex = th.data('active-index');
		if (th.data('from-width') != undefined) options.fromWidth = parseInt(th.data('from-width'));
		
		new Accordion(th, options);
  });
  
  $('.last-breadcrumb-item').click(function(e){
    e.preventDefault();
    return false;
  }); 
});


function loginWithFacebook(btn){
    if(typeof fb_controller != 'undefined' && fb_controller){
        FB.api('/me', {fields:'email,last_name,first_name,id'}, function(response){
          var responseData = response;
          var keepFieldId = btn.data('keep-field');
          if (keepFieldId) {
            var keepField = $('#'+keepFieldId);
            if (keepField.length > 0 && keepField.prop('checked')) {
              responseData.keep_logged_in = keepField.val();
            }
          }
          
          var privacyId = btn.data('privacy-field');
          if (privacyId) {
            var privacyField = $('#'+privacyId);
            if (privacyField.length > 0 &&privacyField.prop('checked')) {
              responseData.privacy = privacyField.val();
            }
          }

          responseData.lang = typeof current_lang !== 'undefined' ? current_lang : '';
          responseData.current_url = document.location.href;

          $.ajax({
              url:base_url+fb_controller,
              dataType:'json',
              type:'post',
              data:responseData,
              success: function(resp){
                  if(resp.success){
                    if(typeof ga != 'undefined'){
                      ga('send', 'event', 'User Actions', 'Facebook login');
                    }
                      if(typeof resp.url != 'undefined' && resp.url)
                          document.location.href = resp.url;
                      else {
                          document.location.reload();
                      }
                  }else{
                    if(typeof resp.message != 'undefined' && resp.message){
                      systemMessenger.showErrorMessage(resp.message);
                    }

                      setTimeout(function(){
                          btn.attr('disabled', false);
                          btn.removeClass("show-animation");
                      }, 500);

                  }
              }
            });
        });
    }
}


// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
  var timeout;
  return function() {
    var context = this,
      args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

var dictionary = [];
$.dictionary = function(element, type, callback) {
  if (typeof type === 'undefined') {
    if ($.isArray(element)) {
      element.each(function(index, el) {
        type.push('fr');
      });
    } else {
      type = 'fr';
    }
  }

  if (typeof callback === 'undefined') {
    callback = {};
  }

  if (typeof dictionary[type] === 'undefined' || typeof dictionary[type][element] === 'undefined') {
    $.ajax({
      type: "POST",
      url: base_url + 'ajaxcontroller/translate',
      data: {
        'element': element,
        'type': type
      },
      dataType: "json",
      success: function(rData) {
        if (typeof dictionary[type] === 'undefined')
          dictionary[type] = [];

        dictionary[type][element] = rData;

        callback.call(undefined, rData);
      }
    });
  } else {
    callback.call(undefined, dictionary[type][element]);
  }

};

$.enableButton = function(button) {
  button.prop('disabled', false).removeClass('disabled');
  if ((text = button.data('disabed-text')) !== undefined) {
    var tmp = text;
    button.html(text).data('disabled-text', tmp);
  }
};

$.disableButton = function(button) {
  button.prop('disabled', true).addClass('disabled');
  if ((text = button.data('disabed-text')) !== undefined) {
    var tmp = text;
    button.html(text).data('disabled-text', tmp);
  }
};

var messageHideSeconds = 10;
$.systemMessage = function(message, type) {
  if (type == undefined) type = 'info';
  var cont = $('.system-messages');
  if (cont.length) {
    if (message != undefined && type != undefined) {
      cont.children('span').removeClass('error info').addClass(type).html(message);
    }
    cont.addClass('has-message');
    setTimeout(function() {
      cont.removeClass('has-message');
    }, messageHideSeconds * 1000);
  }
};

$.addInputError = function(input, error) {
  input.addClass('has-error').tipso({
    background: '#55136F',
    maxWidth: '100%',
    tooltipHover: false,
    content: error,
    onHide: function() {
      input.tipso('destroy');
    }
  });
};

$.removeInputError = function(input) {
  input.removeClass('has-error').tipso('destroy');
};


$.fn.serializeObject = function() {
  var o = {};
  var a = this.serializeArray();
  $.each(a, function() {
    if ((match = this.name.match(/(.+)\[(.+)\]/))) {
      if (o[match[1]] == undefined) o[match[1]] = {};
      o[match[1]][match[2]] = this.value || '';
    } else {
      if (o[this.name] !== undefined) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || '');
      } else {
        o[this.name] = this.value || '';
      }
    }
  });
  return o;
};

function svg(id) {
  return '<span class="svg"><svg class="icon ' + id + '"><use xlink:href="#' + id + '" /></svg></span>';
}

function logoutUser() {
  if (typeof sessionStorage != 'undefined') {
    sessionStorage.clear();
  }
  $.ajax({
    url: profil_controller + '/logout',
    dataType: 'json',
    type: 'post',
    data: {
      lang: (typeof current_lang !== 'undefined' ? current_lang : '')
    },
    success: function(resp) {
      if (resp.success) {
        if (typeof resp.url != 'undefined' && resp.url)
          document.location.href = resp.url;
        else {
          document.location.reload();
        }
      }
    }
  });
}

function openRemodalFromUrl() {
  if(window.location.hash){
    var hash = window.location.hash;
    hash = hash.substring(1, hash.length);
    openRemodalContent(hash);
  }
}

function openRemodalContent(remodal_id) {
  var tmp = $('[data-remodal-id="'+remodal_id+'"]');
  if(tmp.length > 0){
    tmp.remodal().open();
  }
}

function selectTofShopPoint(evt) {
    if (evt.origin == 'https://webcas.expressone.hu') {
      var data = evt.data;
      var parent = $('.tof-package-remodal');

      $('#takeover_address_firstname').val(data.name);
      $('#takeover_address_zip').val(data.zip_code);
      $('#takeover_address_city').val(data.city);
      $('#takeover_address_address_line').val(data.street);

      if (parent.length > 0) {
        parent.remodal().close();
      }
    }
  }
