/* form validation */
var validate_profil_form = false;

jQuery.validateForm = function ( $form, validate_action ){
	if(!validate_profil_form)
	{
		$.ajax({
			type: "POST",
			url: validate_action,//profil_controller + '/validate_form/' + form_type,
			dataType: "json",
			data: $form.serialize(),
			beforeSend: function(){
				$('body').addClass('loading');
				validate_profil_form = true;
				$form.find('button').attr('disabled', true);
			},
			success: function(rData){
				validate_profil_form = false;
				$form.find('button').attr('disabled', false);

				if( rData.valid )
				{
					$form.submit();
				}
				else
				{
					$.each( $form.find('input'), function() {
						$.removeInputError($(this));
					});
					$.each( $form.find('select'), function() {
						$.removeSelectError($(this));
					});

					$form.find('.errored_section').removeClass('errored_section');
					
					$.each( rData.field_errors, function(i, v){
						var c_input = $form.find('[name="'+i+'"]');
						if(c_input.length) {
							//console.log(c_input.is('select'));
							if(c_input.is('select'))
							{
								$.showSelectError(c_input, v);
							}
							else
							{
								$.showInputError(c_input, v);
							}
						}
					});

					if(typeof rData.bc_field_errors !== 'undefined')
						$.each( rData.bc_field_errors, function(i, v){
							var c_input = $form.find('[id='+v+']');
							if(c_input.length) {
								$.showNumberError(c_input, v);
							}
						});

					if(typeof rData.errored_sections !== 'undefined' && rData.errored_sections.length)
						$.each(rData.errored_sections, function(k, v) {
							$('#' + v).addClass('errored_section');
						});

					if(typeof rData.notification !== 'undefined' && rData.notification)
						$.showNotification(rData.notification, 'error');

					
					if(typeof rData.needDatePricker !== 'undefined' && rData.needDatePricker){
						$( rData.needDatePricker ).removeClass('hasDatepicker');

						_datePicker( {selector:rData.needDatePricker, minDate:rData.minDate, maxDate:rData.maxDate} );
						/*
							$( "#guarantee_buying_date" ).removeClass('hasDatepicker');
						*/
					}				
				}
			},
			complete: function (){
              setTimeout(function(){$('body').removeClass('loading');}, 500);
          }
		});
	}
};