(function (window) {
    function SearchBox(params) {
        try {
            if (params) {
                this.setParams(params);
            }

            this.init();
        } catch (e) {
            console.error(e);
        }
    }

    SearchBox.prototype = {
        container: undefined,
        searchField: undefined,
        searchButton: undefined,
        opened: false,
        delay: 500,
        search_input_name: 'q',
        max_items: 5,
        search_site_url: 'http://pwstudio.synology.me:9092/',
        sendedForm: false,
        texts: {
            more_btn_txt: 'További %COUNTER% találat',
            no_result: 'Nincs találat a %TERM% kifejezésre'
        },
        init: function () {
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function () {
            this.container = $('.search-outer');
            this.searchField = this.container.find('.search-field');
            this.searchButton = this.container.find('[data-purpose="search-form-btn"]')
        },
        addDOMEvents: function () {
            var _self = this;

            this.searchField.keyup(function (e) {
                if (e.keyCode == 13 && !_self.sendedForm) {
                    var value = _self.searchField.val();
                    if (value.trim() != '') {
                        document.location.href = _self.getSearchSiteUrl() + '?' + _self.getSearchInputName() + '=' + encodeURIComponent(value);
                    }
                }
            });

            if (this.searchButton && this.searchButton.length > 0) {
                this.searchButton.click(function () {
                    if (!_self.sendedForm) {
                        var value = _self.searchField.val();
                        if (value.trim() != '') {
                            document.location.href = _self.getSearchSiteUrl() + '?' + _self.getSearchInputName() + '=' + encodeURIComponent(value);
                        }
                    }
                });
            }

            _self.container.find('.close-search-box').click(function () {
                if (_self.isOpened()) {
                    _self.closeSearchWindow();
                }
            });

            this.searchField.autocomplete({
                minLength: 2,
                appendTo: '.search-box-outer',
                source: function (request, response) {
                    var data = {};
                    data[_self.getSearchInputName()] = request.term;
                    data.lang = typeof current_lang !== 'undefined' ? current_lang : 'hu';
                    $.ajax({
                        url: ajax_controller + '/search',
                        dataType: 'json',
                        type: 'post',
                        data: data,
                        success: function (resp) {
                            var result = [];
                            if (resp.items.length > 0) {
                                var terms = request.term.replace(/\[|\]|\\|\/|\(|\)|\.|\+|\*|\?/gi, '').split(' ');
                                var term = terms.filter(function (term) {
                                    return term.length > 0
                                }).join('|');
                                var reg = new RegExp('(' + term + ')', 'gi');

                                var count = _self.getMaxItems() < resp.items.length ? _self.getMaxItems() : resp.items.length;
                                var moreProduct = 0;
                                for (var i = 0; i < resp.items.length; i++) {
                                    var tag = resp.items[i];
                                    if (count > i) {
                                        var matches = tag.label.match(reg);
                                        if (matches !== null && matches.length > 0) {
                                            tag.label = tag.label.replace(reg, '<span class="selected">$1</span>');
                                        }
                                        result.push(tag);
                                    } else {
                                        if (tag.type === 'product') {
                                            moreProduct++;
                                        }
                                    }
                                }
                                
                                
                                if (resp.items.length > count && moreProduct > 0) {
                                    var buttonLabel = _self.getText('more_btn_txt');
                                    if (buttonLabel) {
                                        buttonLabel = buttonLabel.replace('%COUNT%', moreProduct);
                                    }

                                    var button = {
                                        id: 0,
                                        value: request.term,
                                        label: buttonLabel,
                                        url: _self.getSearchSiteUrl() + '?' + _self.getSearchInputName() + '=' + encodeURIComponent(request.term)
                                    };

                                    result.push(button);
                                }
                            } else {
                                var noResultLabel = _self.getText('no_result');
                                if (noResultLabel) {
                                    noResultLabel = noResultLabel.replace('%TERM%', request.term);
                                }
                                result.push({
                                    id: -1,
                                    value: request.term,
                                    label: noResultLabel
                                });
                            }

                            response(result);
                        }
                    });
                },
                open: function () {
                    if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
                        $('.ui-autocomplete').off('menufocus hover mouseover');
                    }
                },
                select: function (event, ui) {
                    if (ui) {
                        _self.sendedForm = true;
                        document.location.href = ui.item.url;
                    }
                },
                focus: function (event, ui) {
                    if (ui && ui.item && parseInt(ui.item.id)) {
                        return false;
                    }

                    return true;
                }
            }).data('ui-autocomplete')._renderItem = function (ul, item) {
                if (item && parseInt(item.id) == 0) {
                    return $('<li>', {
                        class: 'more-result'
                    }).append('<span class="button"><span class="text">' + item.label + '</span></span>').appendTo(ul);
                } else if (item && parseInt(item.id) === -1) {
                    return $('<li>', {
                        'class': 'no-result',
                        'html': '<span class="text">' + item.label + '</span>'
                    }).appendTo(ul);
                }
                return $('<li></li>').data('item.autocomplete', item).append(item.label).appendTo(ul);
            };

            this.searchField.autocomplete("widget").addClass("search-autocomplete");
        },
        setParams: function (params) {
            if (params.search_input_name) {
                this.setSearchInputName(params.search_input_name);
            }
            if (params.search_site_url) {
                this.setSearchSiteUrl(params.search_site_url);
            }
            if (params.texts) this.setTexts(params.texts);
        },
        openSearchWindow: function () {
            var _self = this;
            this.opened = true;
            $('html, body').addClass('noscroll');

            this.container.addClass('opened');
            setTimeout(function () {
                _self.searchField.focus();
            }, _self.delay);
        },
        closeSearchWindow: function () {
            this.opened = false;
            $('html, body').removeClass('noscroll');
            this.searchField.val('');
            this.container.removeClass('opened');
        },
        isOpened: function () {
            return this.opened;
        },
        setSearchInputName: function (name) {
            this.search_input_name = name;
        },
        getSearchInputName: function () {
            return this.search_input_name;
        },
        setMaxItems: function (max_items) {
            this.max_items = max_items;
        },
        getMaxItems: function () {
            return this.max_items;
        },
        setSearchSiteUrl: function (search_site_url) {
            this.search_site_url = search_site_url;
        },
        getSearchSiteUrl: function () {
            return this.search_site_url;
        },
        getText: function (key) {
            return typeof this.texts[key] !== 'undefined' ? this.texts[key] : key;
        },
        setTexts: function (texts) {
            this.texts = $.extend({}, this.texts, texts);
        }
    };

    window.searchBox = SearchBox;

})(window);
