(function(window){
  function ShoppingStreetMegaMenu(container, params) {
    try {
      if (!container || (container && container.length == 0)) {
        throw 'Container is required!';
      }

      this.container = container;
      if (params) {
        this.setParams(params);
      }

      this.init();
    } catch (error) {
      console.error(error);
    }
  }

  ShoppingStreetMegaMenu.prototype = {
    container: undefined,
    innerContent: undefined,
    mainMenuItems: undefined,
    secondMenuContainer: undefined,
    thirdMenuContainer: undefined,
    logoContainer: undefined,
    headerOpener: undefined,
    megamenuButton: undefined,
    secondLevelItems: {},
    thirdLevelItems: {},
    init: function() {
      this.setElements();
      this.addDOMEvents();
    },
    setElements: function() {
      var _self = this;

      this.innerContent = this.container.find('[data-purpose="megamenu-inner"]');

      this.logoContainer = $('[data-purpose="logo-container"]');
      
      this.headerOpener = $('[data-purpose="megamenu-opener"]');
      

      this.megamenuButton = $('[data-purpose="megamenu-button"]');
      this.mainMenuItems = this.container.find('[data-purpose="megamenu-item"]');
      this.secondMenuContainer = this.container.find('[data-purpose="second-level-container"]');
      this.thirdMenuContainer = this.container.find('[data-purpose="third-level-container"]');
      this.firstMenuContainer = this.container.find('[data-purpose="first-level-container"]');

      this.container.find('[data-purpose="second-level-menu"]').each(function(){
        _self.secondLevelItems[$(this).data('parent')] = $(this);
      });

      this.container.find('[data-purpose="third-level-menu"]').each(function(){
        _self.thirdLevelItems[$(this).data('parent')] = $(this);
      });
    },
    addDOMEvents: function() {
      var _self = this;
      
      if (this.mainMenuItems && this.mainMenuItems.length > 0) {
        this.mainMenuItems.hover(function(){
          var level = parseInt($(this).data('level'));
          var id = $(this).data('id');
          var target = undefined;
          if (id) {
            if (level === 1 && typeof _self.secondLevelItems[id] !== 'undefined') {
              target = _self.secondLevelItems[id];
            } else if (level === 2 && typeof _self.thirdLevelItems[id] !== 'undefined') {
              target = _self.thirdLevelItems[id];
            }


            if (target) {
              _self.container.addClass('opened')
              _self.showMenu(level+1, target);
            } else if (_self.container.hasClass('opened') && level === 1) {
              _self.container.removeClass('opened');
              _self.secondMenuContainer.removeClass('active');
            } else if (level === 2 && _self.thirdMenuContainer.hasClass('active')) {
              _self.thirdMenuContainer.removeClass('active');
            }

            if (level < 3) {
              if (level === 2) {
                _self.secondMenuContainer.find('.active-item').removeClass('active-item');
              } else if (level === 1) {
                _self.secondMenuContainer.find('.active-item').removeClass('active-item');
                _self.firstMenuContainer.find('.active-item').removeClass('active-item');
              }

              $(this).addClass('active-item');
            }
          }
        });
      }
    
      if (this.headerOpener && this.headerOpener.length > 0) {
        this.headerOpener.hover(function(){
          $(this).addClass("hovered");
          _self.openListContent();
        }, function(e) {
          var target = $(e.relatedTarget);
          var valid = target.hasClass('logo-content-outer') || target.parents('.megamenu').length > 0;

          if (!valid) {
            _self.closeMegamenu();
          }
        });
      }

      if (!this.container.hasClass('always-open') && this.megamenuButton && this.megamenuButton.length > 0) {
        this.megamenuButton.hover(function(){
          _self.openListContent();
        });
      }

      if (this.innerContent) {
        this.innerContent.hover(function(){

        }, function(){
          _self.closeMegamenu();
        });
      }

      $(document).on('megamenu.header_position_changed', function(){
        _self.closeMegamenu();
      });
    },
    openListContent: function() {
      if (!this.container.hasClass('visible')) {
        this.container.addClass('visible');
        if (this.logoContainer && this.logoContainer.length > 0) {
          this.logoContainer.addClass('hovered');
        }
      }
    },
    closeMegamenu: function() {
      this.container.removeClass('opened visible');
      this.secondMenuContainer.removeClass('active');
      this.thirdMenuContainer.removeClass('active');
      this.firstMenuContainer.find('.active-item').removeClass('active-item');
      this.logoContainer.removeClass('hovered');
      this.headerOpener.removeClass('hovered');
      $('.megamenu-list-container').scrollTop(0);
    },
    showMenu: function(level, item) {
      if (level === 2) {
        if (!this.secondMenuContainer.hasClass('active')) {
          this.secondMenuContainer.addClass('active');
        }

        if (this.thirdMenuContainer.hasClass('active')) {
          this.thirdMenuContainer.removeClass('active');
        }

        this.secondMenuContainer.find('.active').removeClass('active');
        this.thirdMenuContainer.find('.active').removeClass('active');
      } else if (level === 3) {
        if (!this.thirdMenuContainer.hasClass('active')) {
          this.thirdMenuContainer.addClass('active');
        }

        this.thirdMenuContainer.find('.active').removeClass('active');
      }

      item.addClass('active');

    },
    setParams: function(params) {

    }
  };

  window.MegaMenu = ShoppingStreetMegaMenu;
})(window);
