(function(window){
    function ShoppingStreetImageLoader() {
        try {
            this.init();
        } catch (error) {
            console.error(error);
        }
    };

    ShoppingStreetImageLoader.prototype = {
        parallaxItems:[],
        init: function() {
            this.loadImages();
            this.setElements();
            this.addDOMEvents();
        },
        setElements: function() {
            var _self = this;
            
            $('[data-purpose="parallax-content"]').each(function(){
                _self.parallaxItems.push($(this));
            });

            $(document).on('shoppingstreet.product_slider_rendered', function(e){
                _self.loadImages();
            });

            $(document).on('shoppingstreet.category_page_content_changed', function(e) {
                _self.loadImages();
            });
        },
        loadImages: function() {
            this.loadImageItems();
            this.replaceObjectToImage();
            this.loadImageBackgrounds();
        },
        addDOMEvents: function() {
            var _self = this;
            
            if (this.parallaxItems.length > 0 && !isTouchDevice()) {
                $(window).on('scroll', function(){
                    _self.checkParallax();
                });
            }
        },
        checkParallax: function() {
            console.log('ezt adott projekt felül kell írni');
        },
        loadImageItems: function() {
            $('[data-purpose="after-load-image"]').each(function(){
                var src = $(this).data('src');
                if (src) {
                    $(this).attr('src', src);
                    var parentClass = $(this).data('extra-parent-class');
                    $(this).on('load', function(){
                        $(this).removeAttr('data-purpose');
                        $(this).addClass('loaded');

                        if (parentClass) {
                            $(this).parent().addClass(parentClass);
                        }
                    });
                }
            });
        },
        replaceObjectToImage: function() {
            $('[data-purpose="replace-object-to-img"]').each(function(){
                var src = $(this).data('src');
                var alt = $(this).data('alt');
                var classList = $(this).attr('class');
                if (src) {
                    var options = {
                        src:src,
                        alt: alt === undefined ? '' : alt,
                    };
                    
                    if (classList) {
                        options.class = classList;
                    }

                    var img = $('<img />', options);

                    img.insertAfter($(this));
                }

                $(this).remove();
            });
        },
        loadImageBackgrounds: function() {
            $('[data-purpose="image-bg-load"]:not(.initialized)').each(function(){
                var image = $(this).data('image');
                if (image) {
                    var parentClass = $(this).data('extra-parent-class');
                    var style = $(this).attr('style');
                    if (!style) {
                        style = '';
                    }

                    style += 'background-image:url("'+image+'")';
                    $(this).attr('style', style);

                    if (parentClass) {
                        var parentSelector = $(this).data('parent-selector');
                        if (parentSelector) {
                            $(this).parents(parentSelector).addClass(parentClass);
                        } else {
                            $(this).parent().addClass(parentClass);
                        }
                    }
                }

                $(this).addClass('initialized');
            });
        }
    };

    window.ShoppingStreetImageLoader = ShoppingStreetImageLoader;
})(window);
