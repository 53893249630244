(function ($) {

    var NewsletterSignup = function (element, options) {
        try {
            if (!element) {
                throw 'Element is required!';
            }

            this.element = element;

            if (options) {
                this.setParams(options);
            }

            return this.init();
        } catch (error) {
            console.error(error);
        }
    };

    NewsletterSignup.prototype = {
        element: undefined,
        form: undefined,
        input: undefined,
        submit: undefined,
        button_selector: 'input[type=submit]',
        handledRecaptcha: false,
        options: {
            ajax: true
        },
        init: function () {
            this.setElements();
            this.addDOMEvents();
        },
        addDOMEvents: function () {
            var _self = this;
            _self.submit.click(function (e) {
                if (_self.options.ajax == true) {
                    e.preventDefault();
                    _self.sendForm();
                }
            });
        },
        setElements: function () {
            this.form = this.element.find('form');
            if (this.form.length == 0) {
                throw 'Form is required!';
            }

            this.submit = this.form.find(this.getButtonSelector());
            this.input = this.form.find('input[type=text]');
        },
        setParams: function (params) {
            if (params.button_selector) this.setButtonSelector(params.button_selector);
            if (params.options) this.setOptions(params.options);
        },
        setButtonSelector: function (button_selector) {
            this.button_selector = button_selector;
        },
        getButtonSelector: function () {
            return this.button_selector;
        },
        setOptions: function (options) {
            this.options = $.extend({}, this.options, options);
        },
        handleRecaptcha: function() {            
            var hasRecaptcha = webshopUtils.hasRecaptcha();
            if (hasRecaptcha) {
                var _self = this;
                _self.form.find('[name="g-recaptcha-response"]').remove();
                _self.form.find('[name="g-recaptcha-action"]').remove();
                
                grecaptcha.ready(function() {
                    grecaptcha.execute(webshopUtils.getRecaptchaSiteKey(), {action: 'newsletter_signup'}).then(function(token) {
                        _self.form.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">');
                        _self.form.prepend('<input type="hidden" name="g-recaptcha-action" value="newsletter_signup">');
                        _self.sendedForm = false;
                        _self.handledRecaptcha = true;    
                        _self.sendForm();                            
                    });
                });
                
            } else {
                this.sendedForm = false;
                this.handledRecaptcha = true;

                this.sendForm();
            }
        },
        sendForm: function () {
            var _self = this;
            
            _self.submit.prop('disabled', true).addClass('disabled');

            if (!_self.handledRecaptcha) {
                this.handleRecaptcha();
                return;
            }

            $.ajax({
                url: _self.form.attr('action'),
                dataType: 'json',
                type: 'post',
                data: _self.form.serialize(),
                beforeSend: function () {
                    _self.form.find('.has-error').removeClass('has-error');
                },
                success: function (rData) {
                    if (rData.msg) {
                        if (typeof systemMessenger != 'undefined') {
                            systemMessenger.showMessage(rData.msg, rData.success ? 'info' : 'error');
                        } else {
                            $.systemMessage(rData.msg, rData.success ? 'info' : 'error');
                        }
                    }

                    if (rData.errors) {
                        $.each(rData.errors, function (key, value) {
                            _self.form.find('input[name="' + key + '"]').addClass('has-error');

                            if (value !== '') {
                                if (typeof systemMessenger != 'undefined') {
                                    systemMessenger.showMessage(value, 'error');
                                } else {
                                    $.systemMessage(value, 'error');
                                }
                            }
                        });
                    }

                    if (!rData.success) {
                        _self.submit.prop('disabled', false).removeClass('disabled');

                        if (webshopUtils.hasRecaptcha()) {
                            _self.handledRecaptcha = false;                            
                        }

                    } else {
                        if (typeof googleTracker != 'undefined') {
                            googleTracker.trackEvent('newsletter_sign_up', 'Lead', 'Newsletter subscribe');
                        } else if (typeof ga != 'undefined') {
                            ga('send', 'event', 'Lead', 'Newsletter subscribe');
                        }

                        _self.submit.prop('disabled', false).removeClass('disabled');
                        _self.input.val('');
                        _self.form.find('input[type="checkbox"]').prop('checked', false);
                    }
                }
            });
        }
    };

    $.fn.newsletterSignup = function (options) {
        return this.each(function () {
            new NewsletterSignup($(this), options);
        });
    };
})(jQuery);
