var etalonSection = undefined;
var headerBorder = undefined;
var header = undefined;

$(document).ready(function(){
	headerBorder = $('[data-purpose="header-scroll-border"]');
	header = $('.main-header');
	if (headerBorder.length == 0) {
		headerBorder = $('.main-header');
	}

	var headerScrollFn = function() {
		var height = headerBorder.outerHeight(true) + headerBorder.offset().top;
		var scrollTop = $(window).scrollTop()
		
		if (height <= scrollTop && !header.hasClass('opened-fix-menu')) {
			header.addClass('opened-fix-menu');
			$('body').addClass('opened-fix-menu');
		} else if (height > scrollTop && header.hasClass('opened-fix-menu')) {
			header.removeClass('opened-fix-menu');
			$('body').removeClass('opened-fix-menu');
			$('.main-header .fix-content').find('.submenu').hide(0, function() {
				var submenu = $(this);
				setTimeout(function(){
					submenu.removeAttr('style');
				}, 250);
			});
		}
	};

	window.addEventListener('touchend', headerScrollFn);
	window.addEventListener('touchmove', headerScrollFn);
	window.addEventListener('scroll', headerScrollFn);
	
	$(document).on('shoppingstreet.product_gallery_opened', function(){
		if (header.hasClass('opened-fix-menu')) {
			header.removeClass('opened-fix-menu');
		};
	});


    $('.mailtolink').click(function(){
		handleMailtoLink($(this));
	});
	
	if (typeof site_id !== 'undefined') {
		if (site_id == 1) {
			etalonSection = $('#membranPress');
			if (etalonSection && etalonSection.length > 0) {
				var scrollFn = function() {
					checkMembranPressState();
				}

				window.addEventListener('touchend', scrollFn);
				window.addEventListener('touchmove', scrollFn);
				window.addEventListener('scroll', scrollFn);
			}
		}
	}

	$('[data-purpose="scroll-to-element"]').click(function(){
		scrollToElement($(this));
	});
});

function handleMailtoLink(th) {
	if (th.attr('href') == 'javascript:void(0)') {
		var hidden = th.children('.emailhidden');
		th.attr('href', 'mailto:' + hidden.data('user') + '@' + hidden.data('domain'));
	}
}

function checkMembranPressState() {
	if (etalonSection && etalonSection.length > 0) {
		var scrollTop = $(window).scrollTop();
		var windowHeight = window.innerHeight;
		var checkPoint = scrollTop + windowHeight;
		var top = etalonSection.position().top;
		var height = etalonSection.outerHeight(true);
		
		var modifier = windowHeight * 0.2;

		if (windowWidth < 600) {
			modifier = windowHeight * -0.2;
		}

		
		var borderPoint = top+height+modifier;
	
		if (checkPoint >= borderPoint && !etalonSection.hasClass('closed')) {
			etalonSection.addClass('closed');
		} else if (checkPoint < borderPoint && etalonSection.hasClass('closed')) {
			etalonSection.removeClass('closed');
		}
	}
}


function scrollToElement(item)
{
	var selector = item.data('target');
	if (selector) {
		var target = $(selector);
		if (target && target.length > 0) {
			$('html, body').animate({
				scrollTop:target.offset().top - 50
			}, 400);
		}
	}
}
