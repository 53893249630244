(function($) {

	var SearchForm = function($element, options){
		this.$element = $element;

		this.defaults = {};
		this.options = jQuery.extend({}, this.defaults, options);

		this.$form = this.$element.find('form');

		if (!this.$form.length) return;

		this.$btn = this.$form.find('input[type=submit]');
		this.$input = this.$form.find('input[type=text]');
		
		this.init();

	};

	SearchForm.prototype = {

		init: function() {
			var _self = this;

			this.$btn.click(function(e) {
				e.preventDefault();
				_self.submitForm();

				return false;				
			});

			this.$input.keydown(function(e) {
				keycode = (e.keyCode || e.which);
				
				if (keycode == 13)
				{
					_self.submitForm();
					return false;
				}

			});

		},

		submitForm: function() {
			var link = this.$form.attr('action') + '/' + this.$input.val();
			window.location = link;
		}
	};

    $.fn.searchForm = function(options) {
        return this.each(function() {
            new SearchForm($(this), options);
        });
    };
})(jQuery);
