(function (window) {
    function ShoppingStreetValidator(options) {
        this.options = {
            error_class: 'has-error',
            success_class: 'success',
            target_classes: ['auto-check-input'],
            feedback_attribute: 'data-feedback-target',
            event_type_attribute: 'data-event-type',
            rule_attribute: 'data-rules',
            remove_on_focus: false,
            mark_input_auto: true
        };

        this.duringInit = true;

        if (options) {
            var optionKeys = Object.keys(options);
            if (optionKeys.length > 0) {
                for (var i = 0; i < optionKeys.lenght; i++) {
                    this.options[optionsKeys[i]] = options[optionsKeys[i]];
                }
            }
        }

        this.init();
    }

    ShoppingStreetValidator.prototype = {
        init: function () {
            if (this.options.target_classes && this.options.target_classes.length > 0) {
                var _self = this;

                for (var i = 0; i < this.options.target_classes.length; i++) {
                    var items = document.getElementsByClassName(this.options.target_classes[i]);

                    if (items.length > 0) {
                        for (var k = 0; k < items.length; k++) {
                            this.addDOMEvent(items[k]);
                        }
                    }
                }
            }
            this.duringInit = false;
        },
        addDOMEvent: function (input) {
            var eventTypes = input.getAttribute(this.options.event_type_attribute);

            if (eventTypes) {
                var _self = this;
                eventTypes = eventTypes.split('|');
                for (var i = 0; i < eventTypes.length; i++) {
                    var type = eventTypes[i];

                    input.addEventListener(type, function () {
                        _self.checkField(this);
                    });

                    if (this.options.remove_on_focus) {
                        input.addEventListener('focus', function () {
                            _self.removeFeedbackClasses(this);
                        });
                    }
                }
            }
        },
        checkField: function (input, form) {
            if (!this.duringInit) {

                var rules = input.getAttribute(this.options.rule_attribute);
                rules = rules ? rules.split('|') : [];
                valid = true;

                if (rules.length > 0) {
                    for (var i = 0; i < rules.length; i++) {
                        var value = input.value;
                        switch (rules[i]) {
                            case 'required':
                                {
                                    valid = this.checkRequired(value);
                                    break;
                                }
                            case 'email':
                                {
                                    valid = this.checkEmail(value);
                                    break;
                                }
                        }

                        if (!valid)
                            break;
                    }
                }

                this.removeFeedbackClasses(input);
                this.setFeedback(input, valid);

                return valid;
            }
        },
        checkRequired: function (value) {
            return value.trim() == '' ? false : true;
        },
        checkNumeric: function (value) {

        },
        checkEmail: function (value) {
            var valid = false
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
                valid = true;
            }

            return valid;
        },
        checkDate: function (value) {
            var valid = false;
            value = value.replace(/\./g, '-');

            var ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf('safari') != -1) {
                if (ua.indexOf('chrome') == -1) {
                    value = value.replace(/\-/g, '/');
                }
            }

            var time = Date.parse(value);
            if (isNaN(time) == false) {
                valid = true;
            }
            return valid;
        },
        setFeedback: function (input, valid) {
            var feedback_class = valid ? this.options.success_class : this.options.error_class;
            var targets = input.getAttribute(this.options.feedback_attribute);
            var parent = this.getParentBy(input, 'form');
            if (targets && targets !== null) {
                targets = targets.split('|');

                for (var i = 0; i < targets.length; i++) {
                    var target = targets[i];
                    var targetElement = input;


                    if (!parent) {
                        parent = document;
                    }

                    if (target == 'label') {
                        targetElement = parent.querySelector('label[for="' + input.getAttribute('id') + '"]');
                    } else if (target == 'parent') {
                        targetElement = input.parentNode;
                    }

                    targetElement.classList.add(feedback_class);
                }
            }

            if (this.options.mark_input_auto) {
                input.classList.add(feedback_class);
            }
        },
        removeFeedbackClasses: function (input) {

            var targets = input.getAttribute(this.options.feedback_attribute);
            var parent = this.getParentBy(input, 'form');
            
            if (targets && targets !== null) {
                targets = targets.split('|');

                for (var i = 0; i < targets.length; i++) {
                    var target = targets[i];
                    if (!parent) {
                        parent = document;
                    }
                    var targetElement = input;

                    if (target == 'label') {
                        targetElement = parent.querySelector('label[for="' + input.getAttribute('id') + '"]');
                    } else if (target == 'parent') {
                        targetElement.parentNode;
                    }

                    targetElement.classList.remove(this.options.error_class);
                    targetElement.classList.remove(this.options.success_class);
                }
            }
            
            if (this.options.mark_input_auto) {
                input.classList.remove(this.options.error_class);
                input.classList.remove(this.options.success_class);
            }

        },
        getParentBy: function (item, selector, by) {
            if (selector === undefined)
                selector = document;

            if (by === undefined)
                by = 'tag';

            var parents = [];
            var p = item.parentNode;

            var i = 0;

            while (p.tagName.toLowerCase() != selector.toLowerCase()) {
                if (p !== null) {
                    p = p.parentNode;
                } else {
                    break;
                }

            }

            return p;
        }
    }


    window.shoppingStreetValidator = ShoppingStreetValidator;
})(window);
