(function(window) {
  function ShoppingStreetMessenger(params) {
    if (params)
      this.setParams(params);

    this.init();
  }


  ShoppingStreetMessenger.prototype = {
    hideTiming: 5,
    timeUnit: 's',
    contentClass: 'system-messages',
    openedClass: 'has-message',
    typeClasses: ['error', 'info'],
    notyf: undefined,
    messages: {
      addedtocart: "%NAME% hozzáadva a kosaradhoz"
    },
    defaultMessenger: 'base',
    initNotyfMessage: false,
    afterJQueryInit: undefined,
    init: function() {
      var _self = this;
      var showMessage = function() {
        _self.showInitMessage();
      };
      
      window.addEventListener('load', showMessage);
    },
    initWithJQuery: function() {
      var _self = this;
      this.notyf = this.getNewNotyf();

      $(window).on('shoppingstreet.addedtocart', function(e, eventData) {
        var name = eventData.variation.name;
        var msg = _self.getMessageText('addedtocart');
        _self.showMessage(msg.replace('%NAME%', '<b>' + name + '</b>'), 'info');
      });

      if (typeof this.afterJQueryInit === 'function') {
        this.afterJQueryInit.call(this);
      }
    },
    setParams: function(params) {
      if (params.timing) this.setHideTiming(params.timing);
      if (params.timeUnit) this.setTimeUnit(params.timeUnit);
      if (params.contentClass) this.setContentClass(params.contentClass);
      if (params.openedClass) this.setOpenedClass(params.openedClass);
      if (params.typeClasses) this.setTypeClasses(params.typeClasses);
      if (params.defaultMessenger) this.setDefaultMessenger(params.defaultMessenger);
      if (params.messages) this.setMessages(params.messages);
      if (params.afterJQueryInit) this.setAfterJQueryInit(params.afterJQueryInit);
    },
    showMessage: function(message, type, messenger_type, hideTime) {
      if (type === undefined) type = 'info';
      if (!messenger_type) {
        messenger_type = this.defaultMessenger;
      }
      switch (messenger_type) {
        case 'notyf':
          switch (type) {
            case 'error':
              this.notyf.alert(message);
              break;
            case 'other':
              this.notyf.info(message);
              break;
            default:
              this.notyf.confirm(message);
              break;
          }
          break;
        case 'base':
          var cont = document.getElementsByClassName(this.getContentClass());
          if (cont.length > 0) {
            if (message !== undefined && type !== undefined) {
              var inner = cont[0].children;
              if (inner.length > 0) {
                this.resetContent(inner[0]);

                inner[0].innerHTML = message;
                this.setContent(inner[0], type);
                if (!hideTime) {
                  _self.getHideTime();
                }
                this.openContainer(cont[0], hideTime);
              }
            }
          }
          break;
      }

    },
    showErrorMessage: function(message, type) {
      this.showMessage(message, 'error', type);
    },
    showInfoMessage: function(message, type) {
      this.showMessage(message, 'info', type);
    },
    showOtherMessage: function(message, type) {
      this.showMessage(message, 'other', type);
    },
    resetContent: function(cont) {
      cont.classList.remove(this.typeClasses);
    },
    showInitMessage: function() {
      var cont = document.getElementsByClassName(this.getContentClass());
      if (cont.length > 0) {
        var inner = cont[0].children;
        var messenger = cont[0].getAttribute('data-messenger');
        if (!messenger) {
          messenger = this.defaultMessenger;
        }
                
        if (inner.length > 0 && inner[0].innerHTML.trim() != '') {
          if (messenger == 'notyf') {
            this.openInitMessageWithNotyf();
          } else {
            var hideTime = cont[0].getAttribute('data-timing');
            hideTime = hideTime.trim() ? parseInt(hideTime) : this.getHideTime();
            
            this.openContainer(cont[0], hideTime);
          }
        }
      }
    },
    openInitMessageWithNotyf: function(){
      var content = $('.'+this.contentClass);

      var type = content.data('type');
      var txt = content.find('span').html();
      this.showMessage(txt, type, 'notyf');
    },
    setContent: function(item, className) {
      item.classList.add(className);
    },
    openContainer: function(cont, hideTime) {
      var _self = this;
      if (!hideTime) {
        hideTime = _self.getHideTime();
      }

      if (cont === undefined && this.getContentClass()) {
        cont = document.getElementsByClassName(this.getContentClass())[0];
      }

      cont.classList.add(this.getOpenedClass());

      cont.addEventListener('click', function(e) {
        this.removeEventListener(e.type, arguments.callee);
        _self.closeContainer(this);
      });

      setTimeout(function() {
        cont.classList.contains(_self.getOpenedClass())
        cont.click();
      }, hideTime);
    },
    closeContainer: function(cont) {
      if (cont === undefined && this.getContentClass()) {
        cont = document.getElementsByClassName(this.getContentClass())[0];
      }

      cont.classList.remove(this.getOpenedClass());
    },
    getHideTime: function() {
      var multiplier = 1000;

      if (this.timeUnit == 'ms')
        multiplier = 1;
      else if (this.timeUnit == 'min')
        multiplier = 60 * 1000;

      return this.hideTiming * multiplier;
    },
    setHideTiming: function(timing) {
      this.hideTiming = timing;
    },
    setTimeUnit: function(unit) {
      this.timeUnit = unit;
    },
    setContentClass: function(contentClass) {
      this.contentClass = contentClass;
    },
    setDefaultMessenger: function(defaultMessenger) {
      this.defaultMessenger = defaultMessenger;
    },
    getContentClass: function() {
      return this.contentClass;
    },
    getOpenedClass: function() {
      return this.openedClass;
    },
    getNewNotyf: function() {
      return new Notyf({
        delay: this.getHideTime()
      });
    },
    getMessageText: function(key) {
      return typeof this.messages[key] != 'undefined' ? this.messages[key] : key;
    },
    setMessages: function(messages) {
      var addMessage = false;
      if (Object.keys(messages).length > 0) {
        addMessage = true;
      }
      this.messages = typeof Object.assign != 'undefined' ? Object.assign({}, this.messages, messages) : (addMessage ? messages : this.messages );
    },
    setAfterJQueryInit: function(afterJQueryInit) {
      this.afterJQueryInit = afterJQueryInit;
    }
  };

  window.shoppingStreetMessenger = ShoppingStreetMessenger;
})(window);
