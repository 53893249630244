(function($) {

    var Contactform = function ($element, options) {
    	this.element = $element;

        this.form = this.element.find('form');
        if (!this.form.length) return;

        this.submit = this.form.find('input[type="submit"]');
        this.fields = this.form.find('[data-purpose="contact-field"]');

    	this.init();

    };

    Contactform.prototype = {

    	init : function () {
    		var _self = this;

            _self.submit.click(function(e) {
                e.preventDefault();

                _self.sendForm();

                return false;
            });
    	},

    	sendForm : function () {
    		var _self = this;

    		$.ajax({
                type: "POST",
                url: _self.form.attr('action'),
                data: _self.form.serialize(),
                dataType: "json",
                beforeSend: function() {
                	_self.submit.prop('disabled', true);
                },
                success: function(rData){
                	_self.fields.each(function(ind, el) {
                        var th = $(el);
                		if (rData.errors[th.attr('name')] != undefined) {
                            $.addInputError(th, rData.errors[th.attr('name')]);
                        }
                        else {
                            $.removeInputError(th);
                        }
                	});

                    if(rData.message) {
                        if(typeof systemMessenger != 'undefined'){
                            systemMessenger.showMessage(rData.message, rData.success ? 'info' : 'error');
                        }else{
                            $.systemMessage(rData.message, rData.success ? 'info' : 'error');
                        }
                    }

                    if (rData.success) {
                        _self.form.get(0).reset();
                    }

                },
                complete: function() {
                	_self.submit.prop('disabled', false);
                }
        	});
    	}
    };

    $.fn.contactForm = function(options) {
        return this.each(function() {
            new Contactform($(this), options);
        });
    };
})(jQuery);
